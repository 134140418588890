header{
    --telegram-icon-size: 32px;

    width: 100%;
    height: var(--header-height);
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 154px 16px 80px;
    background-color: #02021E33;
    z-index: 10;

    .logo{
        height: 40px;
    }

    .telegram-icon {
        width: var(--telegram-icon-size);
        height: var(--telegram-icon-size);
        margin-left: 20px;

        img {
            width: 100%;
        }
    }

    nav {
        .burger{
            cursor: pointer;
            display: none;
        }

        ul{
            display: flex;
            justify-content: space-between;
            align-items: center;

            li{
                a{
                    font-size: 14px;
                    margin: 0 4px;
                    padding: 8px 16px;
                    border-radius: 32px;
                    color: #fff;
                    background-color: transparent;
                    transition: .3s;

                    &.active, &:hover{
                        color: #1E1E1E;
                        background-color: #fff;
                    }
                }
            }
        }

        .language-switcher{
            position: absolute;
            top: 50%;
            right: 80px;
            color: #fff;
            transform: translateY(-50%);
            cursor: pointer;

            img{
                width: 24px;
                height: 24px;
                object-fit: contain;
            }

            span{
                position: relative;
                font-size: 12px;
                padding: 0 10px 0 8px;
                text-transform: uppercase;

                &:after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 3px 3px 0 3px;
                    border-color: #fff transparent transparent transparent;
                    transform: translateY(-50%);
                }
            }

            div:not(.list){
                display: flex;
                align-items: center;
            }

            .list{
                position: absolute;
                top: 150%;
                right: 0;
                border: 1px solid rgba(255, 255, 255, 0.3);
                padding: 0 8px;
                border-radius: 8px;

                div{
                    margin: 8px 0;

                    span:after{
                        display: none;
                    }
                }
            }
        }
    }

    .register-tooltip {
        position: absolute;
        left: 50%;
        padding-top: 10px;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;

        transition: .25s ease-in-out opacity;

        &-inner {
            display: flex;
            flex-direction: column;
            padding: 20px;
            background-color: #25223C;
            border-radius: 16px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #25223C;
        }
    }

    .register-tooltip-wrapper {
        position: relative;
        margin-left: 15px;
    }

    .register-tooltip:hover,
    .btn-register:hover + .register-tooltip {
        opacity: 1;
        visibility: visible;
    }

    @media screen and (max-width: 1199.88px){
        padding: 16px 90px 16px 16px;

        nav {
            ul li a {
                padding: 8px 10px;
            }
            .language-switcher{
                right: 16px;
            }
        }
    }

    @media (min-width: 900px) and (max-width: 1200px) {
        nav ul li a {
            padding: 8px 3px;
        }
    }

    @media screen and (max-width: 900px){
        --telegram-icon-size: 24px;

        justify-content: flex-start;
        padding: 8px 16px;

        .logo{
            height: 22px;
            margin-left: 16px;
        }

        .telegram-icon {
            margin-left: 10px;
        }

        nav {
            order: -1;

            .burger{
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 18px;
                height: 12px;
                transition: .3s;
                z-index: 4;

                span{
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    border-radius: 3px;
                    transform-origin: left;
                    transition: all .3s;
                }

                &.close {
                    position: fixed;
                    top: 16px;
                    transform: unset;

                    span{
                        width: 80%;

                        &:first-child{
                            transform: rotate(45deg);
                        }
                        &:nth-child(2){
                            width: 0;
                        }
                        &:last-child{
                            transform: rotate(-45deg);
                        }
                    }

                    + ul{
                        left: 0;
                    }

                    ~ .language-switcher{
                        right: 16px;
                    }
                }
            }

            ul{
                position: fixed;
                left: -100vw;
                top: 0;
                bottom: 0;
                width: 100vw;
                height: 100vh;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                background: linear-gradient(135deg, #120D2F, #2E1B8D);
                transition: .3s;
                z-index: 1;

                li {
                    width: 100%;

                    a{
                        font-size: 24px;
                        display: block;
                        margin: 16px;
                        padding: 12px 16px;
                    }
                }
            }

            .language-switcher{
                position: fixed;
                top: 16px;
                right: calc(100vw + 16px);
                transform: unset;
                z-index: 4;
                transition: .3s;
            }
        }

        .buttons-wrapper {
           margin-left: auto;

            .btn {
                padding-inline: 15px;
                font-size: 12px;
            }
        }
    }

    @media screen and (max-width: 900px) and (orientation: landscape){
        nav ul li a{
            margin: 0 16px;
            padding: 8px 16px;
        }
    }

    @media (max-width: 567px) {
        --btn-padding-x: 10px;
    }
}