.btn {
    --btn-height: 48px;
    --btn-font-size: 18px;
    --btn-lg-min-width: 312px;
    --btn-lg-height: 64px;
    --btn-lg-font-size: 20px;
    --btn-xs-height: 32px;
    --btn-xs-font-size: 12px;
    --btn-padding-x: 30px;

    //min-width: 5em;
    height: var(--btn-height);
    font-size: var(--btn-font-size);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-inline: var(--btn-padding-x);
    white-space: nowrap;
    cursor: pointer;
    transition: 0.3s;
    border-width: 1px;
    border-style: solid;
    border-radius: 32px;

    + .btn {
        margin-top: 15px;
    }

    &-primary {
        color: #fff;
        background-color: #3719CA;
        border-color: #3719CA;

        &:hover {
            background-color: #5734FF;
            border-color: #5734FF;
        }
    }

    &-primary-outline {
        color: #fff;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.3);

        &:hover {
            background-color: #3719CA80;
        }
    }

    &-secondary {
        color: #3719ca;
        background: #ffffff;
        border: 1px solid #fff;

        &:hover {
            color: #fff;
            background-color: transparent;
        }
    }

    &.btn-xs {
        height: var(--btn-xs-height);
        font-size: var(--btn-xs-font-size);
    }

    &.btn-lg {
        min-width: var(--btn-lg-min-width);
        height: var(--btn-lg-height);
        font-size: var(--btn-lg-font-size);
    }

    &.full-width {
        width: 100%;
    }

    @media (max-width: 1400px) {
        --btn-height: 36px;
        --btn-font-size: 16px;
        --btn-lg-min-width: unset;
        --btn-lg-height: 48px;
        --btn-lg-font-size: 16px;
    }

    @media (max-width: 900px) {
        --btn-height: 32px;
    }
}