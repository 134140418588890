.footer {
    padding-block: 14px;
    font-size: 12px;
    color: #fff;
    background-color: #14142D;

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 25px;
        margin-bottom: 15px;
        border-bottom: 1px solid #27243E
    }

    &-links {
        a, p {
            margin-left: 30px;
        }
    }

    &-contact {
        &, a, p {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        a, p {
            margin-left: 20px;
        }
    }

    .contact-icon {
        width: 24px;
        margin-right: 10px;
    }

    .logo {
        height: 40px;
    }

    .copyright {
        color: #706D87;
    }

    @media (max-width: 1200px) {
        text-align: center;

        &-top {
            flex-direction: column;
        }

        &-contact {
            margin-top: 20px;
        }

        .copyright {
            margin-top: 10px;
        }
    }

    @media (max-width: 900px) {

        &-links {
            a, p {
                display: block;
                margin-top: 20px;
                margin-left: unset;
            }
        }

        &-contact {
            width: 100%;
            flex-direction: column;
            border-top: 1px solid #27243E;

            a, p {
                margin-left: unset;
            }

           > * {
                margin-top: 15px;
            }
        }


    }

    @media (min-width: 900px) and (max-width: 1398.99px) {
        flex-direction: column;
    }
}