// @import "normalize.css";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@font-face {
  font-family: "Inter";
  src: url("../public/font/Inter-Medium.ttf");
}

:root {
  --header-height: 72px;
  --heading-1: 48px;
  --heading-4: 24px;
  --container-padding: 64px;

  @media (max-width: 1200px) {
    --heading-1: 32px;
  }
  @media (max-width: 900px) {
    --heading-1: 24px;
    --header-height: 45px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #181041;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #2D1B86;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #181041;
}

body {
  margin: 0;
  overflow-y: scroll;
  font-family: "Inter";
  color: #B7B7E2;
  background: linear-gradient(-135deg, #130E30, #2F1C8D);
}

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
    margin: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  h4 {
    font-size: var(--heading-4);
    margin-bottom: 16px;
  }

  .page-home section {
    //position: relative;
    //z-index: 5;
    //margin-top: -65px;

    @media (min-width: 1200px) {
      &.revenue {
        margin-top: -120px;
      }
    }

    @media (max-width: 576px) {
      &.revenue {
        margin-top: -25px;
      }
    }

  }

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  @media screen and (max-width: 1100px) {
    section {
      margin-top: -24px;
      border-radius: 24px 24px 0 0;
    }
  }


input, textarea {
  outline: none;
  box-shadow: none;
}

.form {
  margin-block: 10px;

  &-item {
    position: relative;
    padding-top: 13px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .form-control.textarea {
      min-height: 190px;
      padding: 16px;

      + .form-label {
        top: 25px;
      }
    }

    .form-control {
      width: 100%;
      height: 48px;
      display: block;
      outline: none;
      padding: 0 16px;
      color: #ffffff;
      background: linear-gradient(89.21deg, #181041 -0.56%, #2D1B86 99.33%);
      border-radius: 24px;
      border: 1px solid #6B60A5;
      transition: all .1s linear;
    }

    .form-control::placeholder {
      color: transparent;
    }

    label {
      position: absolute;
      top: calc(50% - 8px);
      left: 15px;
      pointer-events: none;
      transition: all .1s linear;
      padding: 5px;
      font-size: 12px;
      font-weight: 500;
      color: #999999;
    }

    .form-control:focus + label,
    .form-control:not(:placeholder-shown) + label,
    .form-control.textarea:focus + label,
    .form-control.textarea:not(:placeholder-shown) + label {
      top: -12px;
      left: 0;
      font-size: 11px;
      color: #ffffff
    }
  }

}

.container {
  padding-inline: var(--container-padding);
  margin: auto;

  @media (max-width: 1200px) {
    --container-padding: 16px
  }
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.font-size-20 {
  font-size: 20px;
}

.mt-auto {
  margin-top: auto;
}

.text-primary {
  color: #F0D01A;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.mt-10px {
  margin-top: 10px;
}

.mt-30px {
  margin-top: 30px;
}

.mb-30px {
  margin-bottom: 30px;
}

pre {
  font-family: inherit;
  text-wrap: wrap;
}

.text-muted {
  color: #B7B7E2;
}
