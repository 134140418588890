.our-partners {
    background-color: #14142D;

    .container {
        display: flex;
    }

    .partners-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin-top: 15%;
    }

    .partners-item {
        width: 100%;
        font-size: 16px;
        color: #B7B7E2;
        background-color: #26233D;
        transition: box-shadow .3s ease;
        border-radius: 24px;

        .item-content {
            display: flex;
            justify-content: space-between;
            padding: 0 30px 60px 30px;
        }

        .item-img {
            width: 100%;
            margin-top: -25%;
            transform: scale(1);
            transition: transform .3s ease;
        }

        .item-logo {
            width: 100%;
            max-width: 190px;
            margin-bottom: 10px;
        }

        .item-text {
            text-wrap: balance;
        }

        &:hover {
            box-shadow: 0 8px 5px rgba(0, 0, 0, 0.2);

            .item-img {
                transform: scale(1.02);
            }
        }
    }

    :global .btn {
        align-self: flex-end;
    }

    @media (max-width: 1200px) {
        .partners-item {
            .item-content {
                flex-direction: column;
            }

            :global .btn {
                width: 100%;
                height: var(--btn-lg-height);
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 900px) {
        .partners-item {
            .item-content {
                padding: 0 30px 30px 30px;
            }

            .item-logo {
                width: unset;
                height: 34px;
            }
        }
    }

    @media (max-width: 576px) {
        .partners-item-wrapper {
            flex-direction: column;
            margin-top: 35%;
        }

        .partners-item + .partners-item {
            margin-top: 25%;
        }
    }
}
