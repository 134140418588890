.network {
    background-color: #02021E;

    :global .title {
        padding-top: 64px;
    }

    &-img {
        width: 80%;
        object-fit: contain;
    }

    &-list {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding-block: 40px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        background-color: #3719CA;
        border-radius: 24px;

        :global p:not(.text-muted) {
            font-size: 40px;
            font-weight: 700;
        }
    }

    .list-item {
        width: 100%;
        position: relative;
        padding: 20px;

        + .list-item {
            border-left: 2px solid #2309A2;
        }
    }
    
    @media (max-width: 900px) {
        :global .title {
            padding-top: calc(var(--container-padding) * 5);
            margin-bottom: 10px;
        }

        &-img {
            width: 100%;
        }

        &-list {
            flex-direction: column;
            padding: 0 20px;

            :global p:not(.text-muted) {
                font-size: 24px;
            }
        }

        .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 10px;
            font-size: 12px;

            + .list-item {
                border-left: unset;
                border-top: 2px solid #FFFFFF1A;
            }
        }
    }
    
    @media (min-width: 1200px) {
        &-img {
            height: 700px;
        }
    }
}