.slider {
    --fraction-width: 25px;
    --btn-width: 34px;
    --btn-right: 80px;
    --alpha-goodwin-img-size: 20em;
    --aspect-ratio: 15/8;

    width: 100%;
    height: 100%;
    position: relative;
    font-family: "Montserrat", sans-serif;
    background: linear-gradient(-135deg, #130E30, #2F1C8D);

    .swiper-pagination-fraction {
        width: var(--fraction-width);
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 109px;
        right: calc(var(--btn-right) + var(--btn-width));
        bottom: unset;
        left: unset;
        color: #fff;
        font-size: 14px;
        background-color: #FFFFFF1A;
        backdrop-filter: blur(10px);
    }

    .swiper-button-next {
        right: var(--btn-right);
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    .swiper-button-prev {
        right: calc(var(--btn-right) + var(--btn-width) + var(--fraction-width));
        left: unset;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
    }

    .swiper-button-next,
    .swiper-button-prev {
        width: var(--btn-width);
        height: 40px;
        top: 131px;
        background-color: #FFFFFF1A;
        backdrop-filter: blur(10px);

        &.swiper-button-disabled {
            opacity: 1;

            &:after {
                opacity: .5;
            }
        }

        &:after {
            width: 100%;
            height: 100%;
            content: '';
            font-family: inherit;
        }
    }

    .swiper-button-next:after {
        background: url("../../../public/images/icons/arrow-right.svg") no-repeat center;
    }

    .swiper-button-prev:after {
        background: url("../../../public/images/icons/arrow-left.svg") no-repeat center;
    }

    .swiper-slide {
        width: 100%;
        min-height: 255px;

        .slide-img {
            width: 100%;
            aspect-ratio: var(--aspect-ratio);
        }
    }

    .slide-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        bottom: 22%;
        left: 3.5%;
        margin-top: auto;

        .alpha-goodwin {
            width: var(--alpha-goodwin-img-size);
            margin-top: 10%;
        }
    }

    @media (max-width: 1600px) {
        --aspect-ratio: 16/9;
    }

    @media (max-width: 1400px) {
        --alpha-goodwin-img-size: 200px
    }

    @media (max-width: 1200px) {
        --btn-right: 20px;
    }

    @media (max-width: 900px) {
        --fraction-width: 20px;
        --btn-width: 27px;
        --btn-right: 20px;
        --aspect-ratio: unset;
        --alpha-goodwin-img-size: 8em;

        .btn {
            font-size: 12px;
            order: 2
        }

        .alpha-goodwin {
            margin-bottom: 10%;
        }

        .swiper-button-next,
        .swiper-button-prev {
            height: 24px;
            top: 80px
        }

        .swiper-pagination-fraction {
            height: 24px;
            top: 58px;
        }

        .slide-content {
            bottom: 18%;
        }

        .slide-img {
            //margin-top: var(--header-height);
        }
    }

    @media screen and  (max-width: 576px) {
        .slide-content {
            .alpha-goodwin {
                margin-top: 5%;
            }
        }

        .btn {
            --btn-lg-height: 32px;
            padding-inline: 15px;
        }
    }

    @media (min-width: 390px) and (max-width: 576px) {
        .slide-img {
            margin-top: var(--header-height);
        }
    }

    @media (max-width: 374.98px) {
        .slide-img {
            height: 255px;
            min-height: 100%;
            object-fit: cover;
            object-position: top left;
        }
    }
}
