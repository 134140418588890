.revenue {
    padding-top: calc(var(--container-padding) / 2);
    background-repeat: no-repeat;
    background-position: center right;
    background-size: auto;
    background-color: #02021E;

    .nav-tabs {
        margin-bottom: 40px;
    }

    .nav-item {
        height: calc(var(--btn-height) + 8px);
        font-weight: 700;
        text-transform: uppercase;
        color: #B7B7E2;
        background-color: #14142D;
        border-color: #14142D;

        &.active {
            color: #02021E;
            background-color: #FFFFFF;
        }

        + .nav-item {
            margin-left: 20px;
        }
    }

    .tab-content {
        .tab {
            //width: 50%;
            display: none;
            opacity: 0;
            transition: opacity 0.3s ease;

            &.active {
                display: block;
                opacity: 1;
            }
        }
    }

    .tab-text {
        width: 70%;

        p {
            margin-bottom: 45px;
        }
    }

    .cpa-info {
        width: fit-content;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        padding: 32px 24px;
        margin-block: 40px;
        font-size: 20px;
        text-transform: uppercase;
        background: url("../../../public/images/revenue/spa-icon.png") no-repeat bottom right / contain, linear-gradient(155.2deg, #1F1F37 -1.02%, #14142D 60.51%);
        border-radius: 24px;


        .info-text + .info-text {
            margin-top: 10px;
            font-weight: 700;
        }

    }

    .share-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .share-item {
        min-width: 140px;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 16px 24px;
        margin-block: 40px;
        text-align: center;
        background-color: #14142D;
        border-radius: 24px;

        .percent {
            font-size: 40px;
            font-weight: 700;
        }

        .number {
            margin-bottom: 0;
            font-size: 24px;
            font-weight: 500;
        }

        .ftd-mouth {
            font-size: 16px;
            font-weight: 400;
        }
    }

    @media (min-width: 900px) {
        min-height: 830px;
        background-position: top right;
        background-size: 80%;

        &:global(.tab-1) {
            background-image: url("../../../public/images/revenue/desktop-tab1.png");
        }

        &:global(.tab-2) {
            background-image: url("../../../public/images/revenue/desktop-tab2.png");
        }

        &:global(.tab-3) {
            background-image: url("../../../public/images/revenue/desktop-tab3.png");
        }

        &:global(.tab-4) {
            background-image: url("../../../public/images/revenue/desktop-tab4.png");
        }

        .share-item {
            + .share-item {
                margin-left: 20px;
            }

            &:after {
                content: "";
                height: 16px;
                position: absolute;
                top: 50%;
                right: 24px;
                left: 24px;
                transform: translateY(-50%);
                background-image: url('../../../public/images/line.png');
                background-size: 100% 100%;
            }
        }
    }

    @media (min-width: 1400px) {
        min-height: 830px;
        background-size: auto;

        .tab-text {
            width: 60%;
        }

        .nav-item {
            font-size: 24px;
        }
    }

    @media (min-width: 900px) {
        .share-item {
            &:nth-child(1) {
                height: 150px;
            }

            &:nth-child(2) {
                height: 180px;
            }

            &:nth-child(3) {
                height: 190px;
            }

            &:nth-child(4) {
                height: 200px;
            }

            &:nth-child(5) {
                height: 220px;
            }
        }
    }

    @media (max-width: 900px) {
        background-position: top right;

        &:global(.tab-1) {
            background-image: url("../../../public/images/revenue/mobile-tab1.png");
        }

        &:global(.tab-2) {
            background-image: url("../../../public/images/revenue/mobile-tab2.png");
        }

        &:global(.tab-3) {
            background-image: url("../../../public/images/revenue/mobile-tab3.png");
        }

        &:global(.tab-4) {
            background-image: url("../../../public/images/revenue/mobile-tab4.png");
        }

        .tab-content {
            background-position: top right;

            .tab {
                //width: 100%;

                :global .btn {
                    width: 100%;
                    margin-top: 30px;
                }
            }

        }

        .tab-text {
            width: 70%;
            font-size: 12px;
        }

        .share-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        .share-item {
            width: 100%;
            flex-direction: row;
            padding-block: 11px;
            margin-block: 15px;

            &::after {
                content: "";
                width: 45px;
                position: absolute;
                top: 10px;
                bottom: 10px;
                left: 50%;
                transform: translateX(-50%);
                background: url('../../../public/images/line-vertical.png') no-repeat center;
            }

            :global(.mt-auto) {
                margin-left: auto;
            }

            .percent {
                margin-left: auto;
                order: 1;
                font-size: 32px;
            }

            .number {
                font-size: 20px;
            }

            .ftd-mouth {
                font-size: 12px;
            }

            :global .mt-auto {
                margin-left: unset;
            }

            &:nth-child(1) {
                width: 60%;
            }

            &:nth-child(2) {
                width: 70%;
            }

            &:nth-child(3) {
                width: 80%;
            }

            &:nth-child(4) {
                width: 90%;
            }
        }

        .nav-tabs {
            display: flex;
            align-items: baseline;
            overflow: auto;
        }

        .nav-item {
            height: 32px;
            margin-top: 0;
            font-size: 14px;
        }
    }

    @media (max-width: 576px) {
        .cpa-info {
            min-width: 100%;
        }
    }
}