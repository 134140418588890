.section-wrapper {
    padding-block: var(--container-padding) calc(var(--container-padding) * 2);
    margin-top: calc(var(--container-padding) * -1);
    position: relative;
    z-index: 5;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;

    .title {
        margin-bottom: 40px;
        font-size: var(--heading-1);
        font-weight: 700;
        text-transform: uppercase;
        color: #ffffff;
    }

    @media (max-width: 900px) {
        padding-block: calc(var(--container-padding) / 2) calc(var(--container-padding) * 1.5);

        &:nth-of-type(1) {
            margin-top: calc((var(--container-padding) / 2) * -1);
        }
    }

    @media (min-width: 1200px) {

        &:nth-of-type(1) {
            margin-top: calc(var(--container-padding) * -2);
        }
    }
}