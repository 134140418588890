.why-us {
    padding-bottom: 40px;
    z-index: 6;
    color: #02021E;
    background: linear-gradient(320.7deg, #E9F6FF 4.79%, #FFFFFF 113.46%);
    border-radius: 24px;
    border-bottom: 15px solid #3719CA;

    :global .title {
        color: #02021E;
    }

    &-item-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr ) ;
        gap: 16px;
        margin-bottom: var(--container-padding);
    }

    &-item {
        min-height: 200px;
        position: relative;
        padding: 25px 20px;
        font-size: 16px;
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: auto;
        border-radius: 24px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            border-radius: 24px;
            filter: drop-shadow(0 2px 8px rgba(0,0,0,.15));
        }

        &:nth-child(1)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #F1D9FF 60.51%);
        }

        &:nth-child(2)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #EEEEEE 93.17%), linear-gradient(143.71deg, rgba(139, 158, 245, 0) 26.43%, rgba(232, 29, 124, 0.2) 96.88%);
        }

        &:nth-child(3)::after {
            background: linear-gradient(143.71deg, rgba(242, 183, 204, 0.012) 26.43%, rgba(51, 102, 229, 0.2) 96.88%);
        }

        &:nth-child(4)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #EEEEEE 93.17%), linear-gradient(143.71deg, rgba(215, 231, 248, 0) 26.43%, rgba(241, 168, 53, 0.2) 96.88%);
        }

        &:nth-child(5)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #FFFFFF 60.51%), linear-gradient(141.81deg, rgba(133, 141, 222, 0) 25.35%, rgba(211, 88, 79, 0.4) 97.8%);
        }

        &:nth-child(6)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #FFFFFF 60.51%), linear-gradient(122.17deg, rgba(244, 169, 48, 0) 46.33%, rgba(215, 231, 247, 0.4) 96.93%);
        }

        &:nth-child(7)::after {
            background: linear-gradient(155.2deg, #FFFFFF -1.02%, #FFFFFF 60.51%), linear-gradient(131.32deg, rgba(235, 167, 91, 0) 43.87%, rgba(235, 167, 91, 0.4) 97.34%);
        }

        &:nth-child(8)::after {
            background: linear-gradient(139.43deg, #FFFFFF 28.71%, #C5E2C0 92%);
        }
    }

    .title {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 20px;
    }

    .text {
        padding-right: 20%;
    }

    @media (min-width: 1200px) {
        &-item-wrapper {
            grid-template-columns: repeat(4, 1fr ) ;
        }

        &-item {
            &:nth-child(1) {
                grid-column: 1 ;
                grid-row: 1 / span 2;
            }
            &:nth-child(2) {
                grid-column: 2;
                grid-row: 1;
            }
            &:nth-child(3) {
                grid-column: 3 / span 2;
                grid-row: 1;
            }
            &:nth-child(4) {
                grid-column: 2 / span 2;
                grid-row: 2;
            }
            &:nth-child(5) {
                grid-column: 4;
                grid-row: 2;
            }
            &:nth-child(6) {
                grid-column: 1;
                grid-row: 3;
            }
            &:nth-child(7) {
                grid-column: 2;
                grid-row: 3;
            }
            &:nth-child(8) {
                grid-column: 3 / span 2;
                grid-row: 3;
            }
        }
    }

    @media (max-width: 900px) {
        :global .btn {
            width: 100%;
        }

        &-item-wrapper {
            margin-bottom: 30px;
        }
    }

    @media (max-width: 576px) {
        &-item-wrapper {
            grid-template-columns: repeat(1, 1fr ) ;
        }

        &-item {
            min-height: 160px;
            background-size: 70%;

            &:nth-child(1) {
                min-height: 343px;
            }

            &:nth-child(2) {
                background-size: 30%;
                background-position: top 50px right 0;
            }
        }
    }
}