.page-wrapper {
    display: flex;
    flex: 1 1;
    padding-top: calc(var(--header-height) + 80px);
    color: #b7b7e2;

    &-inner {
        flex: 1;
        padding: 80px 150px 64px;

        @media (max-width: 1200px) {
            padding: 20px 20px 50px;
        }

        @media (min-width: 1200px) and (max-width: 1400px) {
            padding: 80px 80px 64px;
        }
    }

    p {
        font-size: 18px;

        @media (max-width: 992px) {
            font-size: 16px;
        }
    }

    :global(.page-wrapper-title) {
        margin-bottom: 32px;
        font-size: 48px;
        text-transform: uppercase;
        color: #FFFFFF;

        @media (max-width: 900px) {
            font-size: 24px;
            margin-bottom: 16px;
        }
    }

    &:global(.col-2) {
        .page-wrapper-inner {
            display: flex;
            align-items: flex-start;
            gap: 20px;
        }

        :global(.inner-content-img) {
            width: 100%;

            @media (min-width: 1600px) {
                min-width: 50%;
            }

            @media (min-width: 992px) {
                margin-left: auto;
            }

            @media (max-width: 992px) {
                order: 1;
            }
        }

        @media (max-width: 992px) {
            .page-wrapper-inner {
                flex-direction: column;
            }

            :global(.inner-content) {
                order: 2;
            }
        }
    }

    &:global(.terms),
    &:global(.privacy) {
        h3 {
            margin-block: 50px 10px;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            color: #FFFFFF;
        }

        h4 {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
        }

        p {
            max-width: unset;
            margin-top: 10px;
            font-size: 12px;
            line-height: 16px;
            text-align: justify;
        }

        ol {
            counter-reset: item;
            list-style-type: none;
            padding-inline-start: 0;
            margin-top: 15px;
            text-align: justify;

            > li {
                display: block;
                margin-bottom: 15px;
                font-size: 12px;
                line-height: 16px;

                > ul {
                    list-style-type: disc;
                    margin-top: 15px;
                    padding-left: 10px;

                    li {
                        margin-left: 10px;
                    }
                }

                div {
                    margin-block: 50px;
                    p {
                        margin-left: 30px;
                    }
                }

                &:before {
                    counter-increment: item;
                    content: counters(item, ".") " ";
                    font-weight: 700;
                }
            }

            h4 {
                display: inline-block;
            }
        }

        ul {
            margin-top: 15px;
            list-style-type: disc;
            font-size: 12px;
            text-align: justify;
        }

        @media (min-width: 1600px) {
            .page-wrapper-inner {
                padding-inline: 350px;
            }
        }
    }
}