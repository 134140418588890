.accordion {
  padding-inline: 20px;
  cursor: pointer;
  background-color: #26233D;
  border-radius: 16px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;
    color: #FFFFFF;
  }

  &-title {
    font-size: 16px;
    font-weight: 700;
  }

  &-content {
    color: #B6B6DE;
    transition: max-height 0.25s linear, overflow 0.25s linear, padding-bottom 0.25s linear;
  }

  &-btn {
    cursor: pointer;
    background-color: transparent;
    border: 0 none;

    img {
      transform: rotate(0);
      transition: transform .25s linear;
    }
  }

  &.opened {
    .accordion-content {
      overflow: visible;
      padding-bottom: 20px;
    }

    .accordion-btn img {
      transform: rotate(180deg);
    }
  }

  &.closed {
    .accordion-content {
      max-height: 0;
      overflow: hidden;
      padding-bottom: 0;
    }
  }

  + .accordion {
    margin-top: 20px;
  }

  @media (max-width: 1200px) {
      font-size: 12px;
  }
}