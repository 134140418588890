.contact{
    padding: 64px;
    background: #02021E;

    .inner-block{
        position: relative;
        padding: 40px;
        color: #fff;
        background: #3719CA;
        text-align: center;
        border-radius: 42px;

        h2{
            font-size: 44px;
            font-weight: 800;
            line-height: 1.1;
        }

        p{
            font-size: 21px;
            font-weight: 300;
            margin: 24px auto;
            line-height: 1.66;
            color: #B7B7E2;
        }

        .btn {
            min-width: 190px;
            font-weight: 700;
        }

        &:before{
            content: '';
            position: absolute;
            left: -14px;
            bottom: -14px;
            width: 22vw;
            height: 42%;
            background-image: url(../../../public/images/leftcircles.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left bottom;
        }

        &:after{
            content: '';
            position: absolute;
            top: -8px;
            right: -14px;
            width: 19vw;
            height: 42%;
            background-image: url(../../../public/images/rightcircles.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: top right;
        }
    }

    @media screen and (max-width: 1100px){
        padding: 32px 16px;
    }

    @media screen and (max-width: 900px){
        .inner-block{
            padding: 24px 42px;
            border-radius: 11px;
    
            h2{
                font-size: 16px;
            }
    
            p{
                font-size: 12px;
                max-width: unset;
                margin: 16px auto;
                line-height: 1.2;
            }
    
            a{
                font-size: 12px;
                padding: 8px 12px;
                border-radius: 24px;
                line-height: 1.1;
                letter-spacing: 1px;
            }
    
            &:before{
                left: 0px;
                bottom: -17px;
                width: 33vw;
                height: 33%;
            }
    
            &:after{
                top: -18px;
                right: -13px;
                width: 29vw;
                height: 30%;
            }

            .btn {
                min-width: 113px;
                height: var(--btn-height);
                font-size: 12px;
            }
        }
    }

    @media (min-width: 1200px) {
        .inner-block {
            p {
                max-width: 48%;
            }
        }
    }
}