.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0, .2);
  backdrop-filter: blur(4px);
}

.modal {
  container-type: inline-size;
  container-name: formContainer;
  width: 525px;
  min-height: 605px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 15px;
  color: #ffffff;
  background: linear-gradient(89.21deg, #2D1B86 -0.56%, #181041 99.33%);
  border-radius: 24px;
  transition: width .25s cubic-bezier(0.165, 0.840, 0.440, 1.000), min-height .25s cubic-bezier(0.165, 0.840, 0.440, 1.000);
  animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

  &-header {
    padding: 25px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  &-footer {
    padding-inline: 25px;
    padding-bottom: 25px;
    margin-top: auto;
  }

  &-body {
    padding: 25px;
  }

  &-btn-close {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    background-color: transparent;
    border: 0 none;

    img {
      width: 100%;
    }
  }

  &-confirm {
    width: 655px;
    min-height: 375px;
    justify-content: center;

    .confirm-text {
      margin-block: 25px 60px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-inline: 25px;
    }

  }

  &-register {
    .modal-header {
      padding-bottom: 10px;
    }

    .btn + .btn {
      margin-top: 20px;
      margin-left: unset;
    }

    .modal-body {
      padding-top: 0;

      p {
        margin-bottom: 30px;
        font-size: 12px;
        text-align: center;
        color: #B7B7E2;
      }
    }

    .btn {
      max-width: 272px;
      min-width: 100%;
    }
  }

  &-contact {
    .modal-footer-btn {
      width: 184px;
      height: 3.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-inline: 2.5em;
      margin: auto;
      cursor: pointer;
      font-size: 1.2em;
      border-width: 1px;
      border-style: solid;
      border-radius: 32px;
      color: #fff;
      background-color: #3719CA;
      border-color: #3719CA;

      &:hover {
        background-color: transparent;
        border-color: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &.blow-down-modal {
    animation: blowDownModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
  }

  @container formContainer (width < 525px) {
    .form-item:not(:last-child) {
      margin-bottom: 20px;
    }

    .confirm-icon {
      width: 80px;
    }

    .modal-confirm-content {
      padding-inline: 0;
    }
  }

  &-xs {
    width: 320px;
    min-height: 258px;
  }
}

@keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@keyframes blowDownModal {
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
}
